import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import NewsPage from './NewsPage';
import NotificationDetail from './NotificationDetail';
import ResearchPage from './ResearchPage';
import ProductPage from './ProductPage';
import AboutPage from './AboutPage';
import FlavorPage from './FlavorPage';
import { AppBar, Toolbar, IconButton, Box, Tabs, Tab, Container, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './App.css';
import notifications from './notificationsData';
import AppleCardsCarouselDemo from './AppleCardsCarouselDemo';
// import { Amplify } from 'aws-amplify';
// import awsconfig from './aws-exports';

// Amplify.configure(awsconfig);

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}.${month}.${day}`;
};

function App() {
  const [value, setValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button component={Link} to="/" onClick={() => setValue(0)}>
          <ListItemText primary="Overview" />
        </ListItem>
        <ListItem button component={Link} to="/news" onClick={() => setValue(1)}>
          <ListItemText primary="News" />
        </ListItem>
        <ListItem button component={Link} to="/flavor" onClick={() => setValue(2)}>
          <ListItemText primary="Flavor" />
        </ListItem>
        <ListItem button component={Link} to="/product" onClick={() => setValue(3)}>
          <ListItemText primary="Product" />
        </ListItem>
        <ListItem button component={Link} to="/research" onClick={() => setValue(4)}>
          <ListItemText primary="Research" />
        </ListItem>
        <ListItem button component={Link} to="/about" onClick={() => setValue(5)}>
          <ListItemText primary="About" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Router>
      <div className="App">
        <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }}>
          <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 1, borderRadius: 0 }}>
                <img src="/connoisseur_logo_white.png" alt="Connoisseur Logo" style={{ width: '50px', height: '50px' }} />
              </IconButton>
              <img src="/connoisseur_logo_typo2.png" alt="Connoisseur Logo Typo" style={{ height: '35px' }} />
            </Box>
            {isMobile ? (
              // モバイルの場合、ハンバーガーメニューアイコンを表示
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ ml: 'auto' }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              // デスクトップの場合、通常のタブを表示
              <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <Tabs value={value} onChange={handleChange} aria-label="nav tabs" textColor="inherit" indicatorColor="primary">
                  <Tab label="Overview" component={Link} to="/" />
                  <Tab label="News" component={Link} to="/news" />
                  <Tab label="Flavor" component={Link} to="/flavor" />
                  <Tab label="Product" component={Link} to="/product" />
                  <Tab label="Research" component={Link} to="/research" />
                  <Tab label="About" component={Link} to="/about" />
                </Tabs>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawer}
        </Drawer>
        <Container sx={{ mt: 5, pt: 5 }}>
          <Routes>
            <Route path="/" element={
              <>
                <section id="video">
                  <div className="d-flex justify-content-center mb-4">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/Ol6Wko-c7e8?start=824"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </section>
                <section id="top">
                  <h2 className="text-start mb-4">Recent News</h2>
                  <div className="recent-news-list">
                    {notifications.slice(0, 5).map((notification, index) => (
                      <Link to={`/news/${index}`} className="recent-news-item" key={index}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <small className="text-muted me-5" style={{ fontSize: '80%' }}>{formatDate(notification.date)}</small>
                            <h5 className="mb-1 text-start" style={{ fontSize: '100%' }}>{notification.title}</h5>
                          </div>
                          <div className="tags">
                            {notification.tags.map((tag, tagIndex) => (
                              <span key={tagIndex} className="badge bg-secondary me-2">{tag}</span>
                            ))}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Link to="/news" className="btn btn-outline-secondary rounded-pill">
                      All News...
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L9.293 8H1.5a.5.5 0 0 1 0-1h7.793l-2.147-2.146a.5.5 0 1 1 .708-.708l3 3z" />
                      </svg>
                    </Link>
                  </div>
                </section>
                {/* AppleCardsCarouselDemo をここに追加 */}
                <section id="carousel">
                  <AppleCardsCarouselDemo />
                </section>
                <section id="contact">
                  <h2>Contact Us</h2>
                  <p>Contact us at: <a href="https://x.com/mtakuma32" target="_blank" rel="noopener noreferrer">https://x.com/mtakuma32</a></p>
                </section>
              </>
            } />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:id" element={<NotificationDetail />} />
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/flavor" element={<FlavorPage />} />
          </Routes>
        </Container>
        <footer className="bg-light text-center py-3">
          <p>
          Powered by <a href="https://www.sakura.ad.jp/corporate/">SAKURA internet</a>.<br/>
            © 2024 TAKUMA OKADA. All rights reserved.
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;